<template>
    <div class="tag-filter">
        <div class="tag-filter-button-wrap">
            <div class="tag-filter-button" :class="{ 'tag-filter-button--expanded': isTagListExpanded }"
            @click="isTagListExpanded = !isTagListExpanded">filter_list</div>
            <div v-if="!isTagListExpanded" class="selected-tag-list">
                <template v-for="(tag, tagIndex) in tags" :key="tag.id">
                    <div v-if="tag.isSelected" class="tag tag--selected" @click="$emit('tagSelect', tagIndex)">{{
                    tag.name
                    }}</div>
                </template>
            </div>
        </div>
        <div v-if="isTagListExpanded" class="tag-list">
            <div class="refresh-button material-symbols-sharp" @click="$emit('tagClear')">refresh</div>
            <template v-for="(tag, tagIndex) in tags" :key="tag.id">
                <div class="tag" :class="{ 'tag--selected': tag.isSelected }" @click="$emit('tagSelect', tagIndex)">{{
                    tag.name
                }}</div>
            </template>
        </div>
    </div>
</template>
<script setup>
import { ref, defineProps, defineEmits } from 'vue';

defineProps({
    tags: Array
});
defineEmits([
    'tagClear',
    'tagSelecte'
])
const isTagListExpanded = ref(false);
</script>
<style>
.tag-filter .tag-filter-button-wrap {
    display: flex;
    border: 2px solid #ff9aab;
    border-radius: 4px;
    height: 36px;
}
.tag-filter .tag-filter-button {
    font-family: 'Material Symbols Sharp';
    font-size: 3rem;
    line-height: 36px;
    text-align: left;
    color: #ffffff;
    background-color: #ffc0cb;
    border: none;
    border-radius: 2px;
    width: 72px;
    padding: 0 39px 0 3px;
    position: relative;
}

.tag-filter .tag-filter-button::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border: none;
    border-radius: 0 1.5px 1.5px 0;
    content: 'add';
    color: #707070;
    background-color: #ffffff;
    cursor: pointer;
}

.tag-filter .tag-filter-button--expanded::after {
    content: 'remove';
}

.tag-filter .tag-list {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 998;
    display: flex;
    flex-wrap: wrap;
    gap: 4px 2px;
    background: #ffffff;
    border: 2px solid #ff9aab;
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 15px;
}

.tag-filter .tag-list .refresh-button {
    cursor: pointer;
    background-color: #ffffff;
    color: #8b8b8b;
    border: 1px solid #8b8b8b;
    border-radius: 100%;
    margin-right: 10px;
}

.tag-filter .tag {
    background-color: #ffffff;
    color: #8b8b8b;
    border: 1px solid #8b8b8b;
    border-radius: 4px;
    padding: 1px 10px;
    font-size: 1.4rem;
    cursor: pointer;
    height: 26px;
    flex-shrink: 0;
}

.tag-filter .tag--selected {
    background-color: #ffc0cb;
    border-color: #ff9aab;
    color: #374e64;
}

.tag-filter .selected-tag-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    gap: 4px 2px;
    padding: 3px 2px;
}

.tag-filter .selected-tag-list:empty {
    padding: 0;
}

@media (max-width: 650px) {
    .tag-filter .selected-tag-list {
        display: none;
    }
}
</style>