import { createRouter, createWebHistory } from "vue-router";
import AsmrVideoSearch from './pages/AsmrVideoSearch'
import NotFound from './components/NotFound'

const routes = [
    {
        path: '/video/asmr/:videoId?',
        name: 'AsmrVideoSearch',
        component: AsmrVideoSearch,
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;