<template>
  <div id="header">
    <h1>Mimiko ASMR Search</h1>
    <div class="controlls">
      <SelectBox :options="sortSelectOptions" @change="sortVideos" />
      <TagFilter :tags="tags" @tag-clear="onTagClear" @tag-select="onTagSelect" />
      <ToggleButton v-model:is-checked="isGridView" :extra-class="'material-symbols-sharp'" :checked-label="'grid_view'"
        :unchecked-label="'view_list'" @input="isGridView = !isGridView" />
    </div>
  </div>
  <div id="body">
    <TransitionGroup name="video" tag="div" class="video-list" :class="{ 'video-list--grid': isGridView }">
      <template v-for="video in videos" :key="video.id">
        <YoutubeVideo v-if="video.isVisible" :video="video" @click-video="showVideoModal" />
      </template>
    </TransitionGroup>
    <PageTopButton :text="'vertical_align_top'" :extra-class="'material-symbols-sharp'" />
  </div>
  <LoadingModal :is-show="isLoading" />
  <YoutubeVideoModal :video="currentVideo" @close="closeVideoModal" />
</template>

<script setup>
import { inject, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import config from 'config';
import LoadingModal from '../components/LoadingModal.vue';
import YoutubeVideo from '../components/YoutubeVideo.vue';
import YoutubeVideoModal from '../components/YoutubeVideoModal.vue';
import TagFilter from '../components/TagFilter.vue';
import SelectBox from '../components/SelectBox.vue';
import ToggleButton from '../components/ToggleButton.vue';
import PageTopButton from '../components/PageTopButton.vue';
import router from '@/router';

const route = useRoute();
const isLoading = ref(true);
const tags = ref([]);
const videos = ref([]);
const currentVideo = ref();
const isGridView = ref(true);
const axios = inject('axios');
function onTagSelect(tagIndex) {
  tags.value[tagIndex].isSelected = !tags.value[tagIndex].isSelected;
  filterVideos();
}
function onTagClear() {
  tags.value.forEach((tag, index) => {
    tags.value[index].isSelected = false;
  });
  filterVideos();
}
function showVideoModal(video) {
  currentVideo.value = video;
  router.replace({ name: 'AsmrVideoSearch', params: { videoId: video.id } });
}
function closeVideoModal() {
  currentVideo.value = null;
  router.replace({ name: 'AsmrVideoSearch' });
}
function filterVideos() {
  const selectedTagIds = tags.value.filter(tag => tag.isSelected).map(tag => tag.id);
  let isVisible = () => true;
  if (selectedTagIds.length > 0) {
    isVisible = (video) => selectedTagIds.every(tagId => video.tags.some(tag => tag.id === tagId));
  }
  videos.value.forEach((video, index) => {
    videos.value[index].isVisible = isVisible(video);
  });
}
function getVideo(videoId) {
  if (videoId === '') {
    return null;
  }

  return videos.value.filter(video => video.id === videoId)[0] ?? null;
}
const sortSelectOptions = [
  {
    'value': 'new',
    'name': '新着順',
    'icon': ''
  },
  {
    'value': 'view',
    'name': '再生数',
    'icon': 'play_arrow'
  },
  {
    'value': 'like',
    'name': '高評価',
    'icon': 'thumb_up'
  },
];
const sortSettings = {
  'new': {
    'prop': 'published_at',
    'direction': 'desc',
  },
  'view': {
    'prop': 'view_count',
    'direction': 'desc',
  },
  'like': {
    'prop': 'like_count',
    'direction': 'desc',
  },
};
function sortVideos(sortType) {
  const sort = sortSettings[sortType];
  const prop = sort.prop;
  const direction = sort.direction === 'asc' ? 1 : -1;
  videos.value.sort((a, b) => {
    if (a[prop] > b[prop]) {
      return 1 * direction;
    }
    if (a[prop] < b[prop]) {
      return -1 * direction;
    }

    return 0;
  });
}
onMounted(() => {
  const tagPromise = axios.get(`${config.api_url}/tag/group`)
    .then((response) => {
      const tagDatas = []
      response.data.data.forEach(tagGroup => {
        tagGroup.tags.forEach(tag => {
          tagDatas.push({
            'id': tag.id,
            'name': tag.name,
            'groupId': tagGroup.id,
            'isGeneral': tag.id === tagGroup.general_tag_id,
            'isSelected': false,
          });
        });
      });
      tags.value = tagDatas;
    })
    .catch((error) => Promise.reject(error.message));
  const videoPromise = axios.get(`${config.api_url}/video`)
    .then((response) => {
      videos.value = response.data.data.map(video => {
        video.isVisible = true;

        return video;
      });
      const requestedVideo = getVideo(route.params.videoId);
      if (!requestedVideo) {
        router.replace({ name: 'AsmrVideoSearch' });
      } else {
        currentVideo.value = getVideo(route.params.videoId);
      }
    })
    .catch((error) => Promise.reject(error.message));

  Promise.all([tagPromise, videoPromise])
    .then(() => isLoading.value = false)
    .catch((error) => console.log(error));
});
</script>

<style>
#app {
  text-align: left;
  color: #2c3e50;
  box-sizing: border-box;
  background-color: #ffffff;
}

#header {
  position: fixed;
  top: 0;
  z-index: 2;
  width: calc(100% - 20px);
  background-color: #ffffff;
  margin: 0 10px;
}

#body {
  padding: 100px 0 50px 0;
  margin: 0 10px;
}

h1 {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  font-size: 2.5rem;
  color: #ff9aab;
}

.controlls {
  position: relative;
  display: flex;
  gap: 0 2px;
}

.controlls .can-toggle {
  margin: 0 0 0 auto;
}

.video-list {
  overflow: hidden;
}

.video-list.video-list--grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 10px;
  --flex-item-count: 6;
}

@media (max-width: 1640px) and (min-width: 1311px) {
  #app {
    --flex-item-count: 5;
  }
}

@media (max-width: 1310px) and (min-width: 981px) {
  .video-list.video-list--grid {
    --flex-item-count: 4;
  }
}

@media (max-width: 980px) and (min-width: 651px) {
  .video-list.video-list--grid {
    --flex-item-count: 3;
  }
}

@media (max-width: 650px) {
  .video-list.video-list--grid {
    --flex-item-count: 2;
  }
}

.video-enter-active,
.video-enter-active,
.video-leave-active,
.video-move {
  transition-property: transform, opacity;
  transition-duration: .3s;
}

.video-enter-active {
  opacity: 0;
  transform: translateY(50px);
}

.video-enter-to,
.video-leave-active {
  opacity: 1;
  transform: translateY(0);
}

.video-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.video-list--grid .video {
  margin: 0;
  flex-shrink: 0;
  flex-basis: calc((100% - (10px * (var(--flex-item-count) - 1))) / var(--flex-item-count));
  position: relative;
}

.video-list--grid .video {
  display: initial;
}

.video-list--grid .thumbnail {
  width: 100%;
  max-width: 100%;
  flex-shrink: initial;
}

.video-list--grid .attributes {
  display: initial;
}

.video-list--grid .attributes .duration,
.video-list--grid .attributes .like-count,
.video-list--grid .attributes .view-count {
  position: absolute;
  margin: 0 2px 2px;
}

.video-list--grid .duration {
  right: 0;
  bottom: 4rem;
}

.video-list--grid .like-count {
  position: absolute;
  left: 0;
  bottom: 6.5rem;
}

.video-list--grid .view-count {
  position: absolute;
  left: 0;
  bottom: 4rem;
}

.video-list--grid .title {
  height: 4rem;
}
</style>
