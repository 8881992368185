import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLazyLoad from 'vue3-lazyload'
import router from './router'

const app = createApp(App);
app.use(router);
app.use(VueLazyLoad, {
    loading: '/img/loading.png',
    error: '/img/notfound.png'
})
app.use(VueAxios, axios);
app.provide('axios', app.config.globalProperties.axios);
app.mount('#app');