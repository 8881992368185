<template>
    <Transition name="modal">
        <div v-if="video" class="video-modal-mask" @click.self="$emit('close')">
            <div class="video-modal-container">
                <div class="video-modal-header">
                    <div class="video-modal-close-button" @click="$emit('close')">close</div>
                </div>

                <div class="video-modal-body">
                    <div class="thumbnail">
                        <a :href="'https://youtube.com/watch?v=' + video.id" target="_blank">
                            <img v-lazy="video.thumbnails.high.url" :alt="video.title" />
                        </a>
                    </div>
                    <div class="video-info">
                        <a :href="'https://youtube.com/watch?v=' + video.id" target="_blank">
                            <div>{{ video.title }}</div>
                            <span>https://youtube.com/watch?v={{ video.id }}</span>
                        </a>
                        <div class="attributes">
                            <div>
                                <span class="attribute-title">配信日</span>
                                <span class="attribute-value">{{ toLocaleDateString(video.published_at) }}</span>
                            </div>
                            <div>
                                <span class="attribute-title">再生時間</span>
                                <span class="attribute-value">{{ video.duration }}</span>
                            </div>
                            <div>
                                <span class="attribute-title">高評価</span>
                                <span class="attribute-value">{{ video.like_count.toLocaleString() }}</span>
                            </div>
                            <div>
                                <span class="attribute-title">視聴数</span>
                                <span class="attribute-value">{{ video.view_count.toLocaleString() }}</span>
                            </div>
                        </div>
                        <div class="selected-tag-list">
                            <template v-for="tag in video.tags" :key="tag.id">
                                <div class="tag tag--selected">{{ tag.name }}</div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="video-modal-footer">
                </div>
            </div>
        </div>
    </Transition>
</template>
<script setup>
import { defineProps } from 'vue';

defineProps({
    video: Object
});
function toLocaleDateString(dateString) {
    return new Date(dateString).toLocaleDateString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
}
</script>
<style>
.video-modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.3s ease;
}

.video-modal-container {
    width: 500px;
    max-width: 90%;
    margin: auto;
    padding: 20px 30px;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.video-modal-body {
    margin: 20px 0;
    font-size: 1.4rem;
}

.video-modal-body a {
    text-decoration: none;
    color: inherit;
}

.video-modal-body img {
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}

.video-modal-body .attributes {
    margin: 5px 0;
}

.video-modal-body .attributes span {
    margin-bottom: 1px;
}

.video-modal-body .attributes .attribute-title {
    display: inline-block;
    background-color: pink;
    width: 7rem;
    padding: 2px 4px;
}

.video-modal-body .attributes .attribute-value {
    display: inline-block;
    width: calc(100% - 7rem);
    padding: 2px 4px;
    border-bottom: 1px solid #f1f1f1;
}

.video-modal-body .tag {
    background-color: #ffffff;
    color: #8b8b8b;
    border: 1px solid #8b8b8b;
    border-radius: 4px;
    padding: 1px 10px;
    font-size: 1.4rem;
    cursor: pointer;
    height: 26px;
    flex-shrink: 0;
}

.video-modal-body .tag--selected {
    background-color: #ffc0cb;
    border-color: #ff9aab;
    color: #374e64;
}

.video-modal-body .selected-tag-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    gap: 4px 2px;
}

.video-modal-header {
    position: relative;
}

.video-modal-close-button {
    position: absolute;
    top: -20px;
    right: -20px;
    font-family: 'Material Symbols Sharp';
    font-size: 2rem;
    cursor: pointer;
}

.video-modal-enter-from {
    opacity: 0;
}

.video-modal-leave-to {
    opacity: 0;
}

.video-modal-enter-from .video-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>