<template>
    <div v-if="isShow" class="loading-modal-mask">
        <div id="loadingAnimations">
            <div class="loading-rabbit">
                <div class="rabbit-body"></div>
                <div class="rabbit-ear rabbit-ear--left"></div>
                <div class="rabbit-ear rabbit-ear--right"></div>
            </div>
            <div class="rabbit-shadow"></div>
            <div class="loading-text">
                <span v-for="(char, index) in loadingText" :key="index" :style="{ 'animation-delay': 0.2 * index + 's' }">{{
                    char
                }}</span>
            </div>
        </div>
    </div>
</template>
<script setup>
import { defineProps } from 'vue';

const loadingText = [...'NowLoading'];
defineProps({
    isShow: Boolean
});
</script>
<style>
#loadingAnimations {
    position: relative;
    --rabbit-size: 150px;
}

.loading-modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 47%, #ffd3cd 47%, #ffd3cd 100%);
}

.loading-text {
    position: relative;
    z-index: 1;
    width: 119px;
    margin: 0 auto;
}

.loading-text span {
    font-size: 2rem;
    color: #2c3e50;
    display: inline-block;
    animation: text-bound .7s ease-in infinite alternate;
}

@keyframes text-bound {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0.5rem);
    }
}

.loading-rabbit {
    position: relative;
    z-index: 2;
    width: var(--rabbit-size);
    height: var(--rabbit-size);
    margin: auto;
    margin-top: calc((100vh - var(--rabbit-size)) / 2 - var(--rabbit-size) / 2);
    animation: rabbit-bound 2s linear 1s infinite;
}

@keyframes rabbit-bound {
    0% {
        transform: scale(1.0, 1.0) translate(0%, 0%);
    }

    10% {
        transform: scale(1.1, 0.9) translate(0%, 5%);
    }

    40% {
        transform: scale(1.2, 0.8) translate(0%, 10%);
    }

    50% {
        transform: scale(1.0, 1.0) translate(0%, 0%);
    }

    60% {
        transform: scale(0.9, 1.2) translate(0%, -30%);
    }

    75% {
        transform: scale(1.0, 1.1) translate(0%, -5%);
    }

    85% {
        transform: scale(1.0, 1.0) translate(0%, 0%);
    }

    100% {
        transform: scale(1.0, 1.0) translate(0%, 0%);
    }
}

.rabbit-body {
    position: absolute;
    width: 50%;
    height: 40%;
    top: 50%;
    left: 24.5%;
    border-radius: 80% 80% 80% 80% / 100% 100% 65% 65%;
    background-color: #ffc0cb;
}

.rabbit-ear {
    position: absolute;
    width: 20%;
    height: 35%;
    top: 20%;
    background-color: #ffc0cb;
}

.rabbit-ear--left {
    left: 25%;
    border-radius: 20% 80% 0% 100% / 20% 100% 0% 80%;
    animation: left-rotate 1s alternate infinite;
    transform-origin: right bottom;
}

@keyframes left-rotate {
    0% {
        transform: rotate(30deg);
    }

    100% {
        transform: rotate(-35deg);
    }
}

.rabbit-ear--right {
    left: 55%;
    border-radius: 80% 20% 100% 0% / 100% 20% 80% 0%;
    animation: right-rotate 1s alternate infinite;
    transform-origin: left bottom;
}

@keyframes right-rotate {
    0% {
        transform: rotate(-30deg);
    }

    100% {
        transform: rotate(35deg);
    }
}

.rabbit-shadow {
    position: relative;
    z-index: 1;
    border-radius: 100%;
    background-color: #2c3e50;
    --shadow-height: calc(var(--rabbit-size) * 0.1);
    width: calc(var(--rabbit-size) * 0.45);
    height: var(--shadow-height);
    margin: calc(var(--rabbit-size) * 0.15 * -1) auto 0 auto;
    animation: shadow-scale 2s linear 1s infinite;
}

@keyframes shadow-scale {
    0% {
        transform: scale(1.0, 1.0);
        opacity: 1;
    }

    10% {
        transform: scale(1.2, 0.9);
        opacity: 1;
    }

    40% {
        transform: scale(1.3, 0.8);
        opacity: 1;
    }

    50% {
        transform: scale(1.0, 1.0);
        opacity: 1;
    }

    60% {
        transform: scale(0.4, 0.9);
        opacity: 0.8;
    }

    75% {
        transform: scale(0.8, 1.0);
        opacity: 0.9;
    }

    85% {
        transform: scale(1.0, 1.0);
        opacity: 1;
    }

    100% {
        transform: scale(1.0, 1.0);
        opacity: 1;
    }
}
</style>