<template>
    <div class="not-found">
        <h1>404 Not Found</h1>
    </div>
</template>
<style>
.not-found {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #c4c4c4;
}

.not-found h1 {
    font-size: 30px;
    color: #888888;
    margin: 0;
}
</style>