<template>
    <Transition name="scroll-top-button">
        <span v-if="isVisible" class="page-top-button" :class="extraClass" @click="toTop">{{
            text
        }}</span>
    </Transition>
</template>
<script setup>
import { ref, defineProps, onMounted } from 'vue';

defineProps({
    'text': String,
    'extraClass': String
});
const isVisible = ref(isShowButton());
function showScrollTop() {
    isVisible.value = isShowButton();
}
function isShowButton() {
    return window.scrollY > 500;
}
function toTop() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}
onMounted(() => {
    window.addEventListener('scroll', showScrollTop, { passive: true });
});
</script>
<style>
.page-top-button {
    width: 70px;
    height: 70px;
    line-height: 70px;
    color: #8b8b8b;
    font-size: 4rem;
    text-align: center;
    background-color: #ffffff;
    border: solid 2px #8b8b8b;
    border-radius: 10px;
    opacity: 0.8;
    position: fixed;
    bottom: 25px;
    right: 25px;
    cursor: pointer;
}

.page-top-button:hover {
    opacity: 1;
}

.scroll-top-button-enter-active,
.scroll-top-button-leave-active {
    transition: opacity 1s ease;
}

.scroll-top-button-enter-from,
.scroll-top-button-leave-to {
    opacity: 0;
}

.scroll-top-button-leave-from,
.scroll-top-button-enter-to {
    opacity: 0.8;
}
</style>